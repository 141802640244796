import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const LinkedIn: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path fill="currentColor" d="M4 4v24h24V4H4ZM2 2h28v28H2V2Z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.493 8.25a1.733 1.733 0 1 0 0 3.466 1.733 1.733 0 0 0 0-3.466ZM14 13v9.621h2.99v-4.76c0-1.254.237-2.47 1.793-2.47 1.532 0 1.554 1.435 1.554 2.551v4.679h2.987v-5.277c0-2.591-.56-4.584-3.585-4.584-1.454 0-2.43.798-2.83 1.555h-.04V13H14ZM9 22.621h3V13H9v9.621Z"
      clipRule="evenodd"
    />
  </Icon>
);
