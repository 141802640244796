import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ContactSolid: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M26.979 22.823c-.635 2.376-2.324 3.394-3.247 3.841-.766.375-1.92.343-2.051.328-2.156-.208-7.653-1.263-12.112-6.565-3.991-4.75-4.548-8.696-4.521-10.625.015-1.076.425-2.074 1.159-2.812.703-.701 1.62-1.304 2.271-1.689a2.088 2.088 0 0 1 2.77.561l3.12 4.252a2.052 2.052 0 0 1-.209 2.693l-.923.915a.515.515 0 0 0-.016.717l4.26 4.569a.526.526 0 0 0 .718.047l1.369-1.128a2.104 2.104 0 0 1 2.575-.089c.688.494 2.12 1.513 3.977 2.755a2.051 2.051 0 0 1 .86 2.23Z"
    />
  </Icon>
);
