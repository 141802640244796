import { isBrowser } from './isBrowser';

export function getCookie(cookieName: string) {
  if (!isBrowser) {
    return null;
  }
  // Split the cookie string into an array of individual cookies
  const cookies = document.cookie.split('; ');

  // Use Array.find() to search for the cookie with the specified name
  const foundCookie = cookies.find((cookie) =>
    cookie.startsWith(`${cookieName}=`)
  );

  // If a matching cookie is found, extract and return its value
  if (foundCookie) {
    return decodeURIComponent(foundCookie.substring(cookieName.length + 1));
  }

  // If not found, return null
  return null;
}

export function setCookie(
  cookieName: string,
  cookieValue: string,
  expirationDays: number
) {
  if (!isBrowser) {
    return;
  }
  try {
    // Calculate the expiration date of the cookie
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);

    // Regex tp parse root domain (window.location.hostname) for sharing cookies between subdomains
    const domainWithoutSubdomain = window.location.hostname.match(
      /[^.]*\.[^.]{2,3}(?:\.[^.]{2,3})?$/
    )?.[0];

    // Build the finalised cookie string and set it in the browser
    const cookie = `${cookieName}=${encodeURIComponent(
      cookieValue
    )};secure;expires=${expirationDate.toUTCString()};${
      domainWithoutSubdomain ? `Domain=.${domainWithoutSubdomain};` : ``
    }path=/`;
    document.cookie = cookie;
  } catch (e) {
    console.log(e);
  }
}
