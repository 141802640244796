import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const CloseSquare: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M30 2.5H2v28h28v-28ZM16 15.086l4.364-4.364 1.414 1.414-4.364 4.364 4.364 4.364-1.414 1.414L16 17.914l-4.375 4.375-1.414-1.414 4.375-4.375-4.364-4.364 1.414-1.414L16 15.086Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h32v32H0z" />
      </clipPath>
    </defs>
  </Icon>
);
