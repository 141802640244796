const borderRadii = {
  none: '0px',
  small: '3px;',
  medium: '4px',
  large: '8px',
  xLarge: '12px',
} as const;

export default borderRadii;

export type ThemeBorderRadii = typeof borderRadii;
export type ThemeBorderRadiiKeys = keyof typeof borderRadii;
