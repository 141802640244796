import { useStaticQuery, graphql } from 'gatsby';

export const useCampaignId = () => {
  const { site } = useStaticQuery(graphql`
    query GetCampaignIdDetails {
      site {
        siteMetadata {
          campaignId
          partnerTrackingId
        }
      }
    }
  `);

  const { campaignId, partnerTrackingId } = site?.siteMetadata || {};

  return { campaignId, partnerTrackingId };
};
