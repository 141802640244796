import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Image } from '@agria/theme/src/components/Image/Image';
import { MiniLoaderInterface } from './MiniLoader.interface';
import { colors as themeColor } from '../../styles/tokens';

const StyledLoader = styled.div`
  border-radius: 50%;
  height: 220px;
  overflow: hidden;
  position: relative;
  width: 220px;

  svg {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
`;

const StyledImage = styled.div`
  ${({ theme: { colors } }) => css`
    background-color: ${colors.utilityWhite};
    border-radius: 50%;
    height: 202px;
    left: 9px;
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    top: 9px;
    width: 202px;

    .gatsby-image-wrapper [data-main-image] {
      border-radius: 50%;
    }

    & > div {
      height: 202px;
      width: 202px;
    }

    img {
      border-radius: 50%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      width: 100%;
    }
  `}
`;

const MiniLoader: FC<MiniLoaderInterface> = ({ image }) => {
  if (!image) {
    return;
  }

  return (
    <StyledLoader>
      <svg
        width={220}
        height={220}
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            x1="8.042%"
            y1="0%"
            x2="65.682%"
            y2="23.865%"
            id="miniLoaderLiner"
          >
            <stop
              stopColor={themeColor.utilityWhite}
              stopOpacity={0}
              offset="0%"
            />
            <stop
              stopColor={themeColor.utilityWhite}
              stopOpacity=".631"
              offset="63.146%"
            />
            <stop stopColor={themeColor.utilityWhite} offset="100%" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)">
            <path
              d="M36 18c0-9.94-8.06-18-18-18"
              id="Oval-2"
              stroke="url(#miniLoaderLiner)"
              strokeWidth={2}
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.9s"
                repeatCount="indefinite"
              />
            </path>
            <circle fill={themeColor.utilityWhite} cx={36} cy={18} r={1}>
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.9s"
                repeatCount="indefinite"
              />
            </circle>
          </g>
        </g>
      </svg>
      <StyledImage>
        <Image src={image} alt="Loading" />
      </StyledImage>
    </StyledLoader>
  );
};

export { MiniLoader };
