import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ContactCircle: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M7 6a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 2h4v4H8V8Zm6 0h4v4h-4V8Zm6 0h4v4h-4V8ZM8 14h4v4H8v-4Zm6 0h4v4h-4v-4Zm6 0h4v4h-4v-4ZM8 20h4v4H8v-4Zm6 0h4v4h-4v-4Zm6 0h4v4h-4v-4Z"
    />
  </Icon>
);
