import { useStaticQuery, graphql } from 'gatsby';

export const useDefaultPromoCode = (): string => {
  const data = useStaticQuery(graphql`
    query PromoCodeQuery {
      site {
        siteMetadata {
          defaultPromoCode
        }
      }
    }
  `);

  return data.site.siteMetadata.defaultPromoCode || '';
};
