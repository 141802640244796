import { currencyDefaults } from './const/currencyDefaults';
/**
 * Formats a number as a GBP currency value.
 */

interface CurrencyOptions {
  currency?: string;
  notation?: 'standard' | 'compact' | 'scientific' | 'engineering';
  compactDisplay?: 'short' | 'long';
}
export const currency = (
  val: number | undefined,
  options?: CurrencyOptions
) => {
  if (val === undefined) {
    return '';
  }
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: options?.currency ?? currencyDefaults.code,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: options?.notation ?? 'standard',
    compactDisplay: options?.compactDisplay ?? 'short',
    unitDisplay: 'long',
  });

  try {
    return formatter.format(val).replace(/\D00(?=\D*$)/, '');
  } catch (e) {
    // If we can't format this, return an empty string
    return '';
  }
};
