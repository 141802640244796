import { Quote } from '@agria/paws/src/types';

/*
Calculates the monthlyPrice based on a series of conditions. 

1.
It first checks if quote.DiscountedRate is not null and greater than 0. 
It also checks if quote.Discount exists and if quote.Discount.Monthly is true, and if quote.Discount.NumberOfMonths equals 12. 
If all these conditions are met, it sets monthlyPrice to quote.DiscountedRate.

2.
It checks if quote.Discount does not exist, and if quote.MultipetPercentage is greater than 0 and quote.DiscountedRate is greater than 0. 
If these conditions are met, it sets monthlyPrice to quote.DiscountedRate.

3.
It checks if isIe is true and if quote.DiscountedRate is not null and greater than 0. If these conditions are met, it sets monthlyPrice to quote.DiscountedRate. 
For Agria IE, a discount of 10% is always applied online.

If none of the conditions are met, monthlyPrice is set to quote.MonthlyRate.


*/
export const getMonthlyPrice = (quote: Quote, isIe?: boolean) => {
  if (!quote) {
    return 0;
  }

  const hasDiscountedRate =
    quote.DiscountedRate !== null && quote.DiscountedRate > 0;
  const hasMonthlyDiscount =
    quote.Discount &&
    quote.Discount.Monthly &&
    quote.Discount.NumberOfMonths === 12;
  const hasFullDiscount =
    quote.Discount &&
    quote.Discount.Amount >= 0 &&
    quote.Discount.Monthly === false;
  const hasMultipetDiscount =
    !quote.Discount &&
    (quote.MultipetPercentage > 0 || quote.MultipetAmount > 0);
  const isIeDiscount = isIe && hasDiscountedRate;

  const monthlyPrice =
    (hasDiscountedRate && hasMonthlyDiscount) ||
    (hasMultipetDiscount && hasDiscountedRate) ||
    (hasFullDiscount && hasDiscountedRate) ||
    isIeDiscount
      ? quote.DiscountedRate
      : quote.MonthlyRate;

  return monthlyPrice;
};
