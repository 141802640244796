import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: MJS workaround to allow in JS file
import { service } from '../client.mjs';
import { ApiResponse, Basket, Policy, Quote } from '../types';
import { dataOrError } from '../utils/dataOrError';

export const GET_BASKET_QUERY_KEY = 'getBasket';
interface GetBasketRequest {
  basketId: string;
}

const mergeDataSets = (data, equineData) => {
  const mergedData = {
    ...data.Payload,
    ...equineData.Payload,
    PaymentSchedule: [
      ...data.Payload.PaymentSchedule,
      ...equineData.Payload.PaymentSchedule,
    ],
    Quotes: [...data.Payload.Quotes, ...equineData.Payload.Quotes],
  };

  return {
    Errors: null,
    HasErrored: false,
    Payload: mergedData,
  };
};

const getBaskets = async ({ basketId }: GetBasketRequest) => {
  if (!basketId) return null;
  // Create the promises for both requests
  const request1 = service.get<ApiResponse<Basket>>(
    `/Quote/GetBasket?basketId=${basketId}&basketReferenceNumber&postcode`
  );

  const request2 = service.get<ApiResponse<Basket>>(
    `/Quote/GetBasketEquine?basketId=${basketId}&basketReferenceNumber&postcode`
  );

  // Use Promise.all to call both requests simultaneously
  const [response1, response2] = await Promise.all([request1, request2]);
  const { data } = response1;

  const { data: equineData } = response2;

  const mergeQuotes = (
    originalQuote: Quote | Policy,
    equineQuote: Quote | Policy
  ) => {
    // sometimes SelectedExtras comes back as an array of objects with Id properties so we need to convert it to an array of ids
    let selectedExtras = equineQuote.SelectedExtras;
    if (
      equineQuote.SelectedExtras &&
      Array.isArray(equineQuote.SelectedExtras) &&
      equineQuote.SelectedExtras.length > 0 &&
      typeof equineQuote.SelectedExtras[0] === 'object'
    ) {
      selectedExtras = equineQuote.SelectedExtras.map((extra) => extra.Id);
    }

    return {
      ...originalQuote,
      ...equineQuote,
      InsurancePremiumTaxValue: originalQuote.InsurancePremiumTaxValue,
      Pet:
        originalQuote.Pet.SpeciesId === '636b067c-dd3e-480d-87e7-d59df08c221c'
          ? equineQuote.Pet
          : originalQuote.Pet,
      SelectedExtras: selectedExtras,
    };
  };
  // NOTE - THE Equine and Pet basket data is merged here,
  // But they do not come back in the same order, so the Pet data is not always in the same place in the array
  const mergedData: ApiResponse<Basket> = {
    ...data,
    Payload: {
      ...data.Payload,
      Quotes: data.Payload.Quotes.map((originalQuote: Quote) =>
        mergeQuotes(
          originalQuote,
          equineData.Payload.Quotes.find(
            (quote: Quote) => quote.Id === originalQuote.Id
          )
        )
      ),
      Policies: data.Payload.Policies.map((originalPolicy: Policy) =>
        mergeQuotes(
          originalPolicy,
          equineData.Payload.Policies.find(
            (policy: Policy) => policy.PolicyId === originalPolicy.PolicyId
          )
        )
      ),
    },
  };

  return dataOrError<Basket>(mergedData);
};

export function useGetBasket() {
  const [basketId, setBasketId] = useState('');
  const res = useQuery({
    queryKey: [GET_BASKET_QUERY_KEY, basketId],
    queryFn: () => getBaskets({ basketId }),
    cacheTime: 0,
    enabled: !!basketId,
    refetchOnMount: 'always'
  });

  const fetchBasket = (newBasketId: string) => {
    if (newBasketId) {
      setBasketId(newBasketId);
    }
  };

  return {
    ...res,
    fetchBasket,
  };
}
