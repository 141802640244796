import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Edit: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M28.3 8.745c-.03-.06-.725-1.52-2.07-2.865-1.345-1.345-2.8-2.04-2.865-2.07l-.655-.31L5.325 20.885 3.5 28.605l7.72-1.825L28.605 9.395l-.31-.655.005.005ZM6.225 25.885l.76-3.335 2.55 2.55-3.31.785Zm2-4.995 11.64-11.64 2.995 2.995-11.64 11.64-2.995-2.995ZM24.31 10.795 21.315 7.8l1.79-1.79c.45.28 1.08.725 1.675 1.32a9.72 9.72 0 0 1 1.32 1.675l-1.79 1.79Z"
    />
  </Icon>
);
