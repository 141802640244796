import { useCallback } from 'react';
import { RequestedQuotes } from '../context/QuoteContext';

const useGetDataLayerContentType = () => {
  const getDataLayerContentType = useCallback(
    (requestedQuotes: RequestedQuotes, _subSpecies?: any) => {
      if (!requestedQuotes || typeof requestedQuotes !== 'object') {
        return 'single pet';
      }

      // Calculate the total number of pets
      const totalPets = Object.values(requestedQuotes).reduce(
        (acc, speciesData) => acc + (speciesData.count || 0),
        0
      );

      // Return 'multipet' if more than one pet, otherwise 'single pet'
      return totalPets > 1 ? 'multipet' : 'single pet';
    },
    []
  );

  return getDataLayerContentType;
};

export default useGetDataLayerContentType;
