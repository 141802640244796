import { useEffect } from 'react';
import { useJourneyTracker } from '@agria/theme/src/hooks/useJourneyTracker';

export const useBrowserBackTracking = () => {
  const { incrementJourneyNumber } = useJourneyTracker();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handlePopState = () => {
      incrementJourneyNumber();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [incrementJourneyNumber]);
};
