import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const CalendarAlt: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.693 27.5H7.306C6.561 27.5 6 26.959 6 26.242V13.5h20v12.742c0 .717-.562 1.258-1.307 1.258ZM7.306 6.5H10v2h2v-2h8v2h2v-2h2.658c.753.025 1.342.57 1.342 1.24v3.76H6V7.74c0-.67.589-1.215 1.306-1.24Zm17.387-2H22v-2h-2v2h-8v-2h-2v2H7.271C5.437 4.564 4 5.987 4 7.74v18.502C4 28.069 5.452 29.5 7.306 29.5h17.387c1.854 0 3.307-1.431 3.307-3.258V7.74c0-1.753-1.438-3.176-3.307-3.24Z"
      clipRule="evenodd"
    />
  </Icon>
);
