import { SpeciesReference } from '../types/global';

export const speciesReference: SpeciesReference = {
  'f4372701-68d2-4459-bdba-b3be753e80d4': {
    name: 'Dog',
    displayName: 'dog',
    litterName: 'Puppy',
    crossBreedSizes: [
      {
        type: 'Small',
        weight: 'Less than 10kg',
        crossBreedId: '1159e82c-00b1-4b31-9c43-e1b9923bb50b',
      },
      {
        type: 'Medium',
        weight: '10kg to 20kg',
        crossBreedId: '882eb730-d513-4811-8453-b5dc2a2fbff3',
      },
      {
        type: 'Large',
        weight: 'More than 20kg',
        crossBreedId: 'd6f2c758-53e3-419f-92a2-a65ea8eaaa3f',
      },
    ],
  },
  'c2597178-071f-4940-825d-4d7351b5ffa4': {
    name: 'Cat',
    displayName: 'cat',
    litterName: 'Kitten',
    crossBreedId: '5561fb7f-e17b-4f67-91f5-0e65015ca819',
  },
  '717c7e3d-09cc-4e66-807a-3527541e51d1': {
    name: 'Rabbit',
    displayName: 'rabbit',
    litterName: 'Kit',
    crossBreedSizes: [
      {
        type: 'Small',
        weight: '',
        crossBreedId: '4cfd9afb-5186-4a96-9af5-6a365016595b',
      },
      {
        type: 'Medium',
        weight: '',
        crossBreedId: '981ed545-4af9-4e6a-aec3-7296f99474c8',
      },
      {
        type: 'Large',
        weight: '',
        crossBreedId: '5e396ca8-02a8-48a2-a61a-1dad49941b1b',
      },
    ],
  },
  '636b067c-dd3e-480d-87e7-d59df08c221c': {
    name: 'Equine',
    litterName: 'Foal',
    displayName: 'horse',
  },
};
