import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { replaceSiteUrl } from './replaceSiteUrl';

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    // domNode instanceof Element &&
    // if (domNode.name === 'img') {
    //   const { src, alt } = domNode.attribs;
    //   return <Img src={src} alt={alt} />;
    // }
  },
};

type markupArg = string | string[];
/**
 * Takes in HTML as input and returns parsed HTML output.
 *
 * @param {markupArg} html - The HTML to be parsed.
 * @returns {string|null} - The parsed HTML output or null if input is invalid.
 */
export const createMarkup = (html: markupArg) => {
  let checkHtml = html;
  if (!checkHtml) {
    return null;
  }
  if (Array.isArray(checkHtml)) {
    checkHtml = checkHtml.join('');
  }
  if (typeof checkHtml !== 'string') {
    return null;
  }
  const videoWrapperDiv = {
    start: '<div class="video-wrapper">',
    end: '</div>',
  };
  const iframeRegex = /<iframe.*?<\/iframe>/g;

  const output: string = checkHtml.replace(
    iframeRegex,
    (match) => videoWrapperDiv.start + match + videoWrapperDiv.end
  );

  return parse(replaceSiteUrl(output), options);
};
