import { useStaticQuery, graphql } from 'gatsby';

interface AffiliateDetails {
  affiliateType: 'vet' | 'breeder' | 'rehoming';
  affiliateTypeIds: string[];
}
export const useAffiliateDetails = (): AffiliateDetails => {
  const { site } = useStaticQuery(graphql`
    query GetAffiliateAc {
      site {
        siteMetadata {
          affiliateType
          affiliateTypeIds
        }
      }
    }
  `);

  return site.siteMetadata;
};
