import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Facebook: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path fill="currentCOlor" d="M4 4.5v24h24v-24H4Zm-2-2h28v28H2v-28Z" />
    <path
      fill="currentCOlor"
      d="M14.012 25.484V17.47H12v-2.91h2.012v-2.146c0-2.49 1.521-3.846 3.743-3.846 1.063 0 1.978.079 2.245.114v2.603h-1.54c-1.21 0-1.443.575-1.443 1.417v1.858h2.881l-.375 2.91h-2.506v8.015h-3.005Z"
    />
  </Icon>
);
