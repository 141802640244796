import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ProgressCompleteInverse: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 3C8.83 3 3 8.83 3 16s5.83 13 13 13 13-5.83 13-13S23.17 3 16 3Zm-1.47 18.425-4.695-4.77 1.425-1.405 3.285 3.34 6.615-6.575 1.41 1.42-8.035 7.99h-.005Z"
      clipRule="evenodd"
    />
  </Icon>
);
