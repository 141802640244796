import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Play: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 16c0 6.066 4.934 11 11 11s11-4.934 11-11S22.066 5 16 5 5 9.934 5 16Zm-2 0C3 8.832 8.832 3 16 3s13 5.832 13 13-5.832 13-13 13S3 23.168 3 16Zm8.5-7v14.273l11.309-7.132Zm2.05 10.559v-6.84l5.419 3.422Zm0 0"
      clipRule="evenodd"
    />
  </Icon>
);
