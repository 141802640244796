import { ApiResponse } from '../types';
import { friendlyErrorMessage } from './handleApiError';
// Some errors don't report as errors, so we need to check for HasErrored
export function dataOrError<T>(data: ApiResponse<T>) {
  if (data.HasErrored) {
    const mapped = friendlyErrorMessage(data.Errors?.[0]?.ErrorCode);
    throw new Error(mapped ?? 'Unknown Error');
  }

  return data.Payload;
}
