import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Twitter: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 28V4h24v24ZM30 2H2v28h28Zm-6.52 6-5.957 6.926L24 24.352h-4.766l-4.359-6.348-5.465 6.348H8l6.246-7.262L8 8h4.766l4.129 6.012L22.066 8Zm-8.523 8.266.633.906 4.308 6.164h2.168l-5.28-7.555-.634-.906-4.062-5.813H9.922Zm0 0"
      clipRule="evenodd"
    />
  </Icon>
);
