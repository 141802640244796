import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Progress: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M27.46 9.06c-.035-.2-.28-1.26-1.745-2.72-1.465-1.46-2.525-1.71-2.725-1.745l-.54-.095L5.945 21.01 4.5 27.555l6.545-1.445L27.55 9.6l-.095-.54h.005ZM7.17 24.88l.515-2.135 1.62 1.62-2.13.515H7.17Zm1.745-3.87 10.78-10.785 2.13 2.13-10.78 10.78-2.13-2.13v.005ZM23.31 10.87l-2.13-2.13 1.87-1.87c.285.17.69.46 1.18.95.495.495.785.9.95 1.175l-1.87 1.87v.005Z"
    />
  </Icon>
);
