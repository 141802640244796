import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ArrowLeft: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.168 9.28 1.414 1.414-4.3 4.3h23.272v2H6.275l4.307 4.307-1.414 1.414-6.718-6.717L9.168 9.28Z"
      clipRule="evenodd"
    />
  </Icon>
);
