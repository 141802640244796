import { useStaticQuery, graphql } from 'gatsby';
import { useMemo } from 'react';

type PawsBrands = Queries.PawsBrandQuery['allPawsBrand']['nodes'];
export type PawsBrand = PawsBrands[0];
export function useBrand(): PawsBrand {
  const {
    site: {
      siteMetadata: { brandId },
    },
    allPawsBrand: { nodes: brands },
  } = useStaticQuery(graphql`
    query PawsBrand {
      site {
        siteMetadata {
          brandId
        }
      }
      allPawsBrand {
        nodes {
          brandId: remoteId
          name
          isDefault
        }
      }
    }
  `);

  function getActiveBrand(
    id: string | undefined,
    brandItems: PawsBrand[]
  ): PawsBrand {
    const activeBrand = brandItems.find(
      (brand) => brand.brandId?.toLowerCase() === id?.toLowerCase()
    );
    return activeBrand ?? brandItems.find((brand) => brand.isDefault)!;
  }

  const activeBrand = useMemo(
    () => getActiveBrand(brandId as string, brands as PawsBrand[]),
    [brandId, brands]
  );

  return activeBrand;
}
