import React, { FC, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useHasMounted } from '@agria/utils/src/hooks/useHasMounted';
import { Typography } from '../typography/Typography';

// Interaface
export interface SitewideMessageProps {
  message: string;
  startTime: string;
  endTime: string;
}

// Styles

const StyledSitewideMessage = styled.div`
  ${({ theme: { space, colors } }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${space.medium};
    margin-bottom: ${space.medium};
    background-color: #f2c2cc;
    text-align: center;
  `};
`;

// JSX

export const SitewideMessage: FC<SitewideMessageProps> = ({
  message,
  startTime,
  endTime,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const hasMounted = useHasMounted();

  useEffect(() => {
    if (!hasMounted) {
      return;
    }

    const now = new Date();
    const startDateTime = new Date(startTime);
    const endDateTime = new Date(endTime);

    // Check if current time is within the scheduled window adn recheck every minute
    if (now >= startDateTime && now <= endDateTime) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    const intervalId = setInterval(() => {
      const now = new Date();
      if (now >= startDateTime && now <= endDateTime) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [hasMounted, startTime, endTime]);

  if (!isVisible) {
    return null;
  }

  return (
    <StyledSitewideMessage>
      <Typography variant="bodyMediumBold">{message}</Typography>
    </StyledSitewideMessage>
  );
};

export default SitewideMessage;
