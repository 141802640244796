import React, { FC, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Html } from '../html/Html';
import { ArrowDown } from '../icon';
import { Link } from '../link/Link';
import { Surface } from '../surface/Surface';
import { Stack } from '../stack/Stack';
import { ThemeColorsKeys } from '../../styles/tokens/colors';
import { useDataLayerPush } from '../../hooks/useDataLayerPush';

// Interface

export interface ExpanderContentProps {
  expanderTitle?: string;
  expanderOpenTitle?: string;
  expanderContent?: string | React.ReactNode;
  backgroundColor?: ThemeColorsKeys;
}

interface StyledExpanderProps {
  isOpen: boolean;
}

// Styles

const StyledExpanderContent = styled(Surface)``;

const StyledExpander = styled(Stack)<StyledExpanderProps>`
  ${({ theme: { space }, isOpen }) => css`
    margin-top: ${space.large};
    /* Animate content opening */
    ${StyledExpanderContent} {
      display: ${isOpen ? 'block' : 'none'};
    }
  `};
`;

// JSX

export const ExpanderContent: FC<ExpanderContentProps> = ({
  expanderTitle,
  expanderOpenTitle,
  expanderContent,
  backgroundColor,
}) => {
  const { dataLayerPush } = useDataLayerPush();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    // Toggle the isOpen state
    const newIsOpen = !isOpen;
    setIsOpen(!isOpen);

    const expanderText = newIsOpen
      ? expanderTitle
      : expanderOpenTitle || expanderTitle;

    // Execute dataLayerPush
    dataLayerPush({
      event: 'click expander',
      expander_class: newIsOpen ? 'expander opened' : 'expander closed', // Set the class based on the new state
      expander_text: expanderText,
    });
  };

  const linkLabel =
    isOpen && expanderOpenTitle ? expanderOpenTitle : expanderTitle;
  return (
    <StyledExpander gap="small" isOpen={isOpen}>
      <Link
        onClick={handleClick}
        label={linkLabel}
        icon={<ArrowDown />}
        aria-expanded={isOpen}
        circle
        rotateIcon={isOpen}
        type="button"
        data-test="expander-link"
      />
      <StyledExpanderContent
        backgroundColor={backgroundColor || 'brandOne10'}
        tab-index={!isOpen && -1}
        aria-hidden={!isOpen}
        padding="fluidSmall"
      >
        <Html>{expanderContent}</Html>
      </StyledExpanderContent>
    </StyledExpander>
  );
};

export default ExpanderContent;
