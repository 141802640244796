import { css, useTheme } from '@emotion/react';
import { ThemeButtonKeys } from '../styles/tokens/buttons';
import { fontWeight } from '../styles/tokens/typography';

export const useButtonStyles = (variant: ThemeButtonKeys) => {
  const theme = useTheme();

  if (!variant) {
    return;
  }

  if (!theme.buttons[variant]) {
    console.warn(`*** ${variant} NOT FOUND! ***`);
    return;
  }

  const buttonVariant = theme.buttons[variant];

  return css`
    background-color: ${theme.colors[buttonVariant.background]};
    border-color: ${theme.colors[buttonVariant.borderColor]};
    font-weight: ${fontWeight.bold};
    color: ${theme.colors[buttonVariant.color]};

    ${buttonVariant.underline &&
    css`
      text-decoration: underline;
    `};

    ${buttonVariant.collapse &&
    css`
      padding: 0;
    `};

    ${buttonVariant.upperCase &&
    css`
      text-transform: uppercase;
    `};

    &:hover {
      background-color: ${theme.colors[buttonVariant.hover.background]};
      border-color: ${theme.colors[buttonVariant.hover.borderColor]};
      ${buttonVariant.hover?.color &&
      `color: ${theme.colors[buttonVariant.hover.color]}`};
      text-decoration: ${buttonVariant.hover.underline ? `underline ` : `none`};
    }
  `;
};
