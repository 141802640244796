import React, { FC } from 'react';
import { SimpleHeader as SimpleHeaderComponent } from '@agria/theme/src/components/headers/simpleHeader/SimpleHeader';
import { useSiteMetadata } from '@agria/theme/src/hooks/useSiteMetadata';
import { siteLinks } from '@agria/theme/src/const/siteLinks';

// Interface

export interface HeaderProps {}

export const Header: FC<HeaderProps> = () => {
  const { contactNumber, companyName } = useSiteMetadata();

  return (
    <SimpleHeaderComponent
      companyName={companyName}
      contactNumber={contactNumber}
      logoLink={siteLinks.brochureware}
    />
  );
};

export default Header;
