import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Expand: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.48 12.414 23.066 11l-7.071 7.071-7.073-7.07-1.414 1.414 7.072 7.071 1.415 1.413 1.413-1.413 7.072-7.072Z"
      clipRule="evenodd"
    />
  </Icon>
);
