import { GlobalLoader } from '@agria/theme/src/components/globalLoader/GlobalLoader';
import React, { FC, useContext } from 'react';
import { SitewideMessage } from '@agria/theme/src/components/sitewideMessage/SitewideMessage';
import { useSiteCountry } from '@agria/theme/src/hooks/useSiteCountry';
import { useBrowserBackTracking } from '@agria/theme/src/hooks/useBrowserBackTracking';
import { GlobalLoaderContext } from '../context/GlobalLoaderContext';
import { QuoteContext } from '../context/QuoteContext';
import { useLoaderImage } from '../hooks/useLoaderImage';
import { Footer } from './Footer';
import { Header } from './Header';

// JSX

export const BaseLayout = ({ children }: React.PropsWithChildren) => {
  const { activeSpecies } = useContext(QuoteContext);
  const { showLoader, heading, subHeading } = useContext(GlobalLoaderContext);
  const { isIe } = useSiteCountry();

  const image = useLoaderImage(activeSpecies);

  useBrowserBackTracking();
  return (
    <>
      <Header />
      {!isIe && (
        <SitewideMessage
          message="We'll be performing essential maintenance on Tuesday 9th July, from 9pm to midnight. Please come back outside of these times to cover your pet."
          startTime="2024-07-09T21:00:00"
          endTime="2024-07-09T11:59:00"
        />
      )}
      <main style={{ position: 'relative' }}>
        {showLoader && (
          <GlobalLoader
            image={image}
            heading={heading || ''}
            subHeading={subHeading || ''}
          />
        )}
        {children}
      </main>
      <Footer />
    </>
  );
};

export default BaseLayout;
