import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Download: FC<IconProps> = (props) => (
  <Icon {...props}>
      <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 28V4h12.915l5.078 5.086L25 28H7ZM19.085 6.001H9v20h14l-.007-16.086-3.908-3.914Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M11 14h10v-2H11v2ZM11 18h10v-2H11v2ZM11 22h10v-2H11v2Z"
    />
  </Icon>
);
