import React, { createContext, useEffect, useMemo, useState } from 'react';
import {
  useAffiliateLookup,
  AffiliateLookupPayload,
} from '@agria/paws/src/hooks/useAffiliateLookup';
import { useCheckBreederEmail } from '@agria/paws/src/hooks/useCheckBreederEmail';
import { UseFormReturn, useForm } from 'react-hook-form';
import { Affiliate } from '@agria/paws/src/types';
import { isBrowser } from '@agria/utils';
import { useAffiliateDetails } from '../hooks/useAffiliateDetails';

interface AuthContextProps {
  id: string | null;
  loginUser: UseMutateFunction<
    Affiliate,
    unknown,
    AffiliateLookupPayload,
    unknown
  >;
  loginError: boolean;
  loginLoading: boolean;
  loginData: Affiliate | undefined;
  formMethods: UseFormReturn<any>;
  onSubmit: (data: any) => void;
  affiliateTypeError: boolean;
  invalidStatusIdError: boolean;
}
interface AuthContextProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext({} as AuthContextProps);
AuthContext.displayName = 'AuthContext';

export const AuthProvider = ({ children }: AuthContextProviderProps) => {
  const {
    mutate: loginUser,
    data: loginData,
    isError: loginError,
    isPending: loginLoading,
  } = useAffiliateLookup();
  const {
    mutate: checkUser,
    data: checkData,
    isError: checkError,
  } = useCheckBreederEmail();

  const [affiliateTypeError, setAffiliateTypeError] = useState(false);
  const [invalidStatusIdError, setInvalidStatusIdError] = useState(false);

  const defaultId = isBrowser
    ? window.sessionStorage.getItem('Agria@AffiliateId')
    : '';
  const [id, setId] = useState<string | null>(defaultId);

  const { affiliateTypeIds, affiliateType } = useAffiliateDetails();

  const formMethods = useForm();
  const { formState } = formMethods;
  const { errors, isValid } = formState;

  useEffect(() => {
    if (invalidStatusIdError) return;

    const newId = loginData?.Id || checkData;
    if (newId) {
      if (
        affiliateTypeIds &&
        affiliateTypeIds.length > 0 &&
        loginData?.AffiliateTypeId &&
        !affiliateTypeIds.includes(loginData.AffiliateTypeId)
      ) {
        setAffiliateTypeError(true);
        return;
      }
      setAffiliateTypeError(false);
      setId(newId);
      window.sessionStorage.setItem('Agria@AffiliateId', newId);
    }
  }, [loginData, checkData, affiliateTypeIds, invalidStatusIdError]);

  const returnValues = useMemo(() => {
    const onSubmit = (formData: any) => {
      if (!isValid) return;
      const payLoad: AffiliateLookupPayload = {};

      if (formData.email) {
        payLoad.email = formData.email;
      }
      if (formData.agriaId) {
        payLoad.agriaId = formData.agriaId;
      }
      if (formData.postcode) {
        payLoad.postcode = formData.postcode;
      }
      // Regex to see if postcodeOrId is a number, then we need to set agriaId else need to set postcode
      if (formData.postcodeOrId) {
        const numberRegEx = /^[0-9]+$/;
        if (numberRegEx.test(formData.postcodeOrId as string)) {
          payLoad.agriaId = formData.postcodeOrId;
        } else {
          payLoad.postcode = formData.postcodeOrId;
        }
      }
      setAffiliateTypeError(false);
      setInvalidStatusIdError(false);

      loginUser(payLoad, {
        onSuccess: (loginResponseData) => {
          if (
            affiliateType === 'vet' &&
            loginResponseData.AppointedRepresentativeStatusId?.toLowerCase() !==
              '8da68463-0134-4a4a-8412-870a022693b7'
          ) {
            setInvalidStatusIdError(true);
            return;
          }
          // If validation passes, set login data
          setId(loginResponseData.Id);
          window.sessionStorage.setItem(
            'Agria@AffiliateId',
            loginResponseData.Id
          );
        },
      });
    };

    return {
      loginError:
        loginError || checkError || affiliateTypeError || invalidStatusIdError,
      loginUser,
      loginData,
      formMethods,
      onSubmit,
      loginLoading,
      id,
      affiliateTypeError,
      invalidStatusIdError,
    };
  }, [
    loginError,
    checkError,
    affiliateTypeError,
    invalidStatusIdError,
    loginUser,
    loginData,
    formMethods,
    loginLoading,
    id,
    isValid,
    affiliateType,
  ]);

  return (
    <AuthContext.Provider value={returnValues}>{children}</AuthContext.Provider>
  );
};
