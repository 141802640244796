import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Tick: FC<IconProps> = ({ title, ...rest }) => (
  <Icon {...rest} title={title || 'Tick icon'}>
    <path
      fill="currentColor"
      d="M10.325 26.42 3 19.095l1.52-1.52 5.805 5.805L27.705 6l1.525 1.52-18.905 18.9Z"
    />
  </Icon>
);
