/* eslint-disable react/no-danger */
import type { GatsbySSR } from 'gatsby';
import React from 'react';
import { QuoteContextProvider } from './src/context/QuoteContext';
import { GlobalLoaderProvider } from './src/context/GlobalLoaderContext';
import { ActiveStepProvider } from './src/context/ActiveStepContext';
import { BaseLayout } from './src/components/BaseLayout';
import { StateDebugger } from './src/components/StateDebugger';

// Note providers normall would go in wrap pageElement but we need the location
export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({
  element,
  props,
}) => (
  <QuoteContextProvider location={props.location}>
    <ActiveStepProvider location={props.location}>
      <GlobalLoaderProvider>
        <BaseLayout {...props}>{element} </BaseLayout>
        <StateDebugger />
      </GlobalLoaderProvider>
    </ActiveStepProvider>
  </QuoteContextProvider>
);

export const onRenderBody: GatsbySSR['onRenderBody'] = ({
  setHeadComponents,
}) => {
  setHeadComponents([
    <script
      key="partytown-vanilla-config"
      dangerouslySetInnerHTML={{
        __html: `partytown = { debug: false }`,
      }}
    />,
  ]);
};
