import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Account: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M27 16c0 6.075-4.925 11-11 11S5 22.075 5 16 9.925 5 16 5a10.97 10.97 0 0 1 8.341 3.829l1.516-1.305A12.97 12.97 0 0 0 16 3C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13c0-1.461-.242-2.891-.71-4.246l-.432-1.14h-9.089c-2.43 0-4.1-.639-5.194-1.679-.367-.35-.64-.72-.833-1.086a2.354 2.354 0 0 1-.176-.401l-1.933.515c.174.653.646 1.547 1.564 2.42 1.473 1.403 3.64 2.23 6.572 2.23h7.7A10.97 10.97 0 0 1 27 16Z"
    />
    <path
      fill="currentColor"
      d="M20.293 20.293A6.048 6.048 0 0 1 16 22.068a6.048 6.048 0 0 1-4.293-1.775l-1.414 1.414A8.048 8.048 0 0 0 16 24.068c2.127 0 4.176-.83 5.707-2.36l-1.414-1.415ZM14.392 16.263h-2A.28.28 0 0 0 12.13 16a.28.28 0 0 0-.263.263h-2c0-1.227 1.036-2.263 2.263-2.263 1.226 0 2.263 1.036 2.263 2.263ZM21.725 16.263h-2a.28.28 0 0 0-.263-.263.28.28 0 0 0-.263.263h-2c0-1.227 1.036-2.263 2.263-2.263 1.226 0 2.263 1.036 2.263 2.263Z"
    />
  </Icon>
);
