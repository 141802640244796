import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: MJS workaround to allow in JS file
import { service } from '../client.mjs';
import { ApiResponse } from '../types';
import { dataOrError } from '../utils/dataOrError';

const getVersion = async () => {
  const { data } = await service.post<ApiResponse<string>>(`/Cache/GetVersion`);
  return dataOrError<string>(data);
};

export function useIsAlive() {
  return useQuery({
    queryKey: ['version'],
    queryFn: () => getVersion(),
  });
}
