import { PaymentScheduleItem } from "@agria/paws/src/types";

export const getHighestPrice = (quote: PaymentScheduleItem[]) => {

    const highestPrice = quote.reduce((accumulator, object) => {
        const value = object.Amount > accumulator ? object.Amount : accumulator;
        return value;
    }
    , 0);
    return highestPrice;
  }