import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Telephone: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      d="M25.4812 22.6836L22.9762 18.6936C22.5912 18.0786 21.9862 17.6536 21.2762 17.4986C20.5662 17.3436 19.8362 17.4736 19.2212 17.8636L17.3762 19.0536C17.0262 18.8836 16.2812 18.3486 15.2362 16.6886C14.1612 14.9786 14.0212 14.0586 14.0162 13.6986L15.8862 12.4936C17.0612 11.7336 17.4662 9.90861 16.7212 8.73361L14.2212 4.74861C13.8362 4.12861 13.2312 3.70361 12.5212 3.54361C11.8112 3.38861 11.0812 3.51861 10.4712 3.91361L8.16622 5.39861C7.23622 5.99361 6.54622 7.12861 6.22622 8.58861C6.06122 9.33861 5.44622 13.4336 9.76622 20.3136C12.1062 24.0436 14.3362 26.4686 16.3962 27.5236C17.6962 28.1886 18.9712 28.5286 20.0912 28.5286C20.9512 28.5286 21.7212 28.3286 22.3412 27.9286L24.6462 26.4436C25.2362 26.0636 25.6662 25.4386 25.8212 24.7336C25.9762 24.0286 25.8562 23.2786 25.4812 22.6836ZM23.3962 24.5086L21.0862 25.9936C20.6812 26.2536 19.4012 26.4836 17.4412 25.4736C15.7562 24.6086 13.8262 22.4586 11.7062 19.0886C7.86622 12.9736 8.33122 9.68861 8.46622 9.09361C8.65122 8.25861 9.00122 7.60361 9.40622 7.34361L11.7162 5.85361C11.7812 5.80861 11.8562 5.78861 11.9262 5.78861C11.9562 5.78861 11.9862 5.78861 12.0162 5.79861C12.1212 5.82361 12.2062 5.88361 12.2662 5.97861L14.7712 9.96861C14.8712 10.1236 14.7912 10.4586 14.6362 10.5636L12.1612 12.1586L12.0262 12.3936C11.6262 13.0736 11.2612 14.6936 13.2862 17.9186C15.3662 21.2336 17.0612 21.4836 17.7012 21.4286L17.9912 21.4036L20.4762 19.7986C20.5662 19.7386 20.6762 19.7186 20.7812 19.7436C20.8862 19.7686 20.9762 19.8286 21.0312 19.9186L23.5312 23.9036C23.6362 24.0686 23.5612 24.3986 23.4012 24.5036L23.3962 24.5086Z"
      fill="currentColor"
    />
  </Icon>
);
