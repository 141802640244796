import { AxiosError } from 'axios';
import * as Sentry from '@sentry/gatsby';
import { ErrorMessage } from '../types';

interface PawsAPIError {
  Errors: ErrorMessage[];
}

// take error code and return a friendly message
export const friendlyErrorMessage = (code: string | number) => {
  let error = '';
  try {
    // ErrorCode

    // make it a string
    const codeAsString = `${code}`;

    // switch (codeAsString) {
    //   case '1036':
    //     error =
    //       'Unfortunately, you cannot activate a free cover policy for yourself';
    //     break;

    //   default:
    //     error = '';
    // }
    switch (codeAsString) {
      case '1001':
        error = 'Null Request Object';
        break;
      case '1002':
        error = 'Invalid Details';
        break;
      case '1003':
        error = 'Authentication Failed';
        break;
      case '1004':
        error = 'Password Update Failed';
        break;
      case '1005':
        error = 'Payment Failed';
        break;
      case '1006':
        error = 'Payment Type Not Supported';
        break;
      case '1007':
        error = 'Rating Request Error';
        break;
      case '1008':
        error = 'Configuration Error';
        break;
      case '1030':
        error = 'Duplication Code';
        break;
      case '1031':
        error = 'Microchip Duplication';
        break;
      case '1032':
        error = 'Registration Duplication';
        break;
      case '1033':
        error = 'Free policy already active for yourself';
        break;
      case '1034':
        error = 'Address Duplication';
        break;
      case '1035':
        error = 'Password Reset Error';
        break;
      case '1036':
        error =
          'Unfortunately, you cannot activate a free cover policy for yourself';
        break;
      case '1101':
        error = 'Email Address Invalid';
        break;
      case '1102':
        error = 'Invalid Product Brand';
        break;
      case '1103':
        error = 'Quote Expired';
        break;
      case '1104':
        error = 'Invalid Product Policy Start Date';
        break;
      case '1105':
        error = 'Free Cover Lapsed';
        break;
      case '1106':
        error = 'Policy Not Live';
        break;
      case '1107':
        error = 'Free Cover Converted';
        break;
      case '1108':
        error = 'Policy Found As Quote';
        break;
      case '1109':
        error = 'Non-Alphanumeric Argument';
        break;
      case '1110':
        error = 'Non-Numeric Argument';
        break;
      case '1111':
        error = 'Quote Converted';
        break;
      case '1112':
        error = 'Quote Found As Free Cover';
        break;
      case '1201':
        error = 'Address Update Failed - Live Policies';
        break;
      case '1900':
        error = 'Duplicate Pet';
        break;
      case '1901':
        error = 'Invalid Payment Type';
        break;
      case '1902':
        error = 'No Outstanding Balance';
        break;
      case '1903':
        error = 'Affiliate Not Found';
        break;
      case '1904':
        error = 'Account Not Found';
        break;
      case '1905':
        error = 'Policy Delegate Not Found';
        break;
      case '1906':
        error = 'Calendar Event Not Found';
        break;
      case '1907':
        error = 'Document Not Found';
        break;
      case '1908':
        error = 'Template Not Found';
        break;
      case '1909':
        error = 'Campaign Not Found';
        break;
      case '1910':
        error = 'Basket Not Found';
        break;
      case '1911':
        error = 'Quote Not Found';
        break;
      case '1912':
        error = 'Pet Photo Not Found';
        break;
      case '1913':
        error = 'Customer Not Found';
        break;
      case '1914':
        error = 'Title Not Found';
        break;
      case '1915':
        error = 'Country Not Found';
        break;
      case '1916':
        error = 'Postcode Blocked';
        break;
      default:
        error = '';
    }
  } catch (checkError) {
    console.debug(checkError);
  }
  return error;
};
// take Axios error and trow the correct error message
export const handleApiError = (error: AxiosError<PawsAPIError>) => {
  Sentry.captureException(error);
  // first see if we can map to a friendly error
  if (error?.response?.data?.Errors?.[0]?.ErrorCode) {
    const mapped = friendlyErrorMessage(
      error.response?.data.Errors[0].ErrorCode
    );


    if (mapped) {
      try {
        Sentry.addBreadcrumb({
          category: "errors",
          message: mapped,
          level: "info",
        });
      } finally {}
      throw new Error(mapped);
    }
  }
  if (error?.response?.status === 400) {
    if (error?.response?.data?.Errors?.[0]?.Message) {
      throw new Error(
        error.response.data.Errors[0].Message ??
          'Please fill out all required fields'
      );
    } else {
      throw new Error('Please fill out all required fields');
    }
  }

  if (error?.response?.status === 500) {
    if (error?.response?.data?.Errors?.[0]?.Message) {
      throw new Error(
        error.response.data.Errors[0].Message ??
          'Something went wrong. Please try again later'
      );
    } else {
      throw new Error('Something went wrong. Please try again later');
    }
  }
};
