import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const CurrencyIe: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M21.13 9.1V7c-3.845 0-7.125 2.435-8.405 5.84H10.5v2.1h1.715c-.04.34-.065.69-.065 1.04 0 .35.025.7.065 1.04H10.5v2.1h2.225c1.28 3.405 4.555 5.84 8.4 5.84v-2.1c-2.66 0-4.97-1.52-6.115-3.74h6.115v-2.1h-6.79c-.05-.34-.085-.685-.085-1.04s.035-.7.085-1.04h6.795v-2.1h-6.115c1.145-2.215 3.45-3.74 6.115-3.74Z"
    />
  </Icon>
);
