import { useEffect, useState } from 'react';

const useJourneyTracker = (): {
  journeyNumber: number;
  incrementJourneyNumber: () => void;
  removeJourneyNumber: () => void;
} => {
  const [journeyNumber, setJourneyNumber] = useState<number>(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedJourneyNumber = sessionStorage.getItem('journey_number');
      if (storedJourneyNumber === null) {
        sessionStorage.setItem('journey_number', '0');
      } else {
        setJourneyNumber(parseInt(storedJourneyNumber, 10));
      }
    }
  }, []);

  const incrementJourneyNumber = () => {
    if (typeof window !== 'undefined') {
      setJourneyNumber((prev) => {
        sessionStorage.setItem('journey_number', (prev + 1).toString());
        return prev + 1;
      });
    }
  };

  const removeJourneyNumber = () => {
    if (typeof window !== 'undefined') {
      setJourneyNumber(0);
      sessionStorage.removeItem('journey_number');
    }
  };

  return { journeyNumber, incrementJourneyNumber, removeJourneyNumber };
};

export { useJourneyTracker };
