import React, { useEffect } from 'react';

interface ContextValue {
  state: any;
  dispatch: React.Dispatch<any>;
}

const PREFIX = 'Agria@';
// Custom hook to sync context to local storage
export const useSyncContextToSessionStorage = (
  key: string,
  value: ContextValue
): void => {
  const fullKey = `${PREFIX}${key}`;

  useEffect(() => {
    const storedValue = window.sessionStorage.getItem(fullKey);
    if (storedValue && !value?.state.activeQuote?.Id) {
      try {
        const payload = JSON.parse(storedValue);

        payload.hasSet = true;
        delete payload.basket;
        value.dispatch({
          type: 'SET_ALL',
          payload,
        });
      } catch (e) {
        console.log(e);
      }
    }
    value.dispatch({
      type: 'SET_HAS_SET',
      payload: true,
    });
    // We really only want this to run on first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const toStore = {
      ...value.state,
    };
    delete toStore.basket;
    window.sessionStorage.setItem(fullKey, JSON.stringify(toStore));
  }, [fullKey, value.state]);
};
