import React, { FC, useId } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import iconSizes, { ThemeIconSizesKeys } from '../../styles/tokens/iconSizes';
import { colors, ThemeColorsKeys } from '../../styles/tokens/colors';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  children?: any;
  size?: ThemeIconSizesKeys;
  color?: ThemeColorsKeys;
  title?: string;
}

interface StyledIconProps {
  size: string;
  color?: ThemeColorsKeys;
}

const StyledIcon = styled.svg<StyledIconProps>`
  ${({ size, color }) => {
    const width = size.split(' ')[0];
    const height = size.split(' ')[1];
    return css`
      width: ${width}px;
      height: ${height}px;
      flex-shrink: 0;

      ${color &&
      css`
        color: ${colors[color]};
      `}
    `;
  }};
`;

export const Icon: FC<React.PropsWithChildren<IconProps>> = ({
  children,
  size = 'medium',
  color,
  title,
  ...rest
}) => {
  const id = useId();
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${iconSizes.medium}`}
      size={iconSizes[size]}
      color={color}
      aria-labelledby={title && id}
      {...rest}
    >
      {title && <title id={id}>{title}</title>}
      {children}
    </StyledIcon>
  );
};
