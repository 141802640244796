const fontFamily = {
  regular: '"Maison Neue", "Maison Neue Fallback", "Times New Roman", serif',
  bold: '"Maison Neue Bold", "Maison Neue Bold Fallback", "Times New Roman", serif',
};

export const fontWeight = {
  regular: 400,
  bold: 700,
};

export interface ThemeType {
  fontFamily?: string;
  fontWeight?: number;
  fontSize?: string;
  lineHeight?: string;
  textTransform?: string;
  letterSpacing?: string;
}

// Clamp is used to create a fluid typography scale.
// We use this tool to convert https://clamp.font-size.app/
// Maximum viewport width is 1200px
// Minimum viewport width is 376px
// Set mobile/Desktop font size in the calculator
// Convert rem to px tool https://nekocalc.com/px-to-rem-converter

const displayBase = {
  fontWeight: fontWeight.bold,
  fontFamily: fontFamily.bold,
};

const headingSmallBase = {
  fontSize: 'clamp(1.3125rem, 1.2269rem + 0.3641vw, 1.5rem)', // 21px => 24px
  lineHeight: 'clamp(1.75rem, 1.6359rem + 0.4854vw, 2rem)', // 28px => 32px
};

const headingExtraSmallBase = {
  fontSize: 'clamp(0.875rem, 0.7039rem + 0.7282vw, 1.25rem)', // 14px => 20px
  lineHeight: 'clamp(1.125rem, 0.8169rem + 1.3146vw, 2rem)', // 18px => 32px
};

const bodyLargeBase = {
  fontFamily: fontFamily.regular,
  fontSize: '1.5rem', // 24px
  lineHeight: '2rem', // 32px
};

const bodyMediumBase = {
  fontFamily: fontFamily.regular,
  fontSize: '1rem', // 16px
  lineHeight: '1.5rem', // 24px
};

const bodySmallBase = {
  fontFamily: fontFamily.regular,
  fontSize: '0.875rem', // 14px
  lineHeight: '1.125rem', // 18px
};

const typography = {
  displayLarge: {
    fontSize: 'clamp(3rem, 2.5437rem + 1.9417vw, 4rem)', // 48px => 64px
    lineHeight: 'clamp(3.5rem, 3.0437rem + 1.9417vw, 4.5rem)', // 56px => 72px
    ...displayBase,
  },
  headingXLarge: {
    fontSize: 'clamp(2.875rem, 2.6468rem + 0.9709vw, 3.375rem);', // 46px => 54px
    lineHeight: 'clamp(3.375rem, 3.1468rem + 0.9709vw, 3.875rem);', // 54px => 62px
    ...displayBase,
  },
  headingLarge: {
    fontWeight: fontWeight.bold,
    fontFamily: fontFamily.bold,
    fontSize: 'clamp(2.375rem, 2.1468rem + 0.9709vw, 2.875rem);', // 38px => 46px
    lineHeight: 'clamp(2.875rem, 2.6468rem + 0.9709vw, 3.375rem);', // 46px => 54px
  },
  headingMedium: {
    fontWeight: fontWeight.bold,
    fontFamily: fontFamily.bold,
    fontSize: 'clamp(1.625rem, 1.5109rem + 0.4854vw, 1.875rem);', // 26px => 30px
    lineHeight: 'clamp(2.125rem, 2.0109rem + 0.4854vw, 2.375rem);', // 34px => 38px
  },
  headingSmall: {
    fontWeight: fontWeight.bold,
    fontFamily: fontFamily.bold,
    ...headingSmallBase,
  },
  headingExtraSmall: {
    fontFamily: fontFamily.regular,
    fontWeight: fontWeight.regular,
    ...headingExtraSmallBase,
  },
  headingExtraSmallBold: {
    fontFamily: fontFamily.bold,
    fontWeight: fontWeight.regular,
    ...headingExtraSmallBase,
  },
  bodyLarge: {
    fontWeight: fontWeight.regular,
    ...bodyLargeBase,
  },
  bodyLargeBold: {
    fontWeight: fontWeight.bold,
    ...bodyLargeBase,
  },
  bodyMedium: {
    fontWeight: fontWeight.regular,
    ...bodyMediumBase,
  },
  bodyMediumBold: {
    fontWeight: fontWeight.bold,
    ...bodyMediumBase,
  },
  bodySmall: {
    fontWeight: fontWeight.regular,
    ...bodySmallBase,
  },
  bodySmallBold: {
    fontWeight: fontWeight.bold,
    ...bodySmallBase,
  },
  caps: {
    fontWeight: fontWeight.bold,
    fontFamily: fontFamily.bold,
    fontSize: '0.875rem', // 14px
    lineHeight: '1rem', // 16px
    textTransform: 'uppercase',
  },
  quote: {
    fontWeight: fontWeight.regular,
    fontFamily: fontFamily.regular,
    fontSize: '2.5rem', // 40px
    lineHeight: '3rem', // 48px
  },
} as const;

export default typography;

export type ThemeTypographyKeys = keyof typeof typography;
export type ThemeTypography = Record<ThemeTypographyKeys, ThemeType>;
