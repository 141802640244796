import queryString from 'query-string';
import { isBrowser } from './isBrowser';

export const getQueryString = <T>(search?: string): T | undefined =>
  search ? (queryString.parse(search) as T) : undefined;

export const createQueryString = (query: Record<string, any>) =>
  queryString.stringify(query);

export const replaceUrlParam = (searchParams: Record<string, any>) => {
  if (isBrowser && window.history.pushState) {
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${searchParams.toString()}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }
};
