import React, { FC, useEffect } from 'react';
import { PageProps } from 'gatsby';
import { useDataLayerPush } from '@agria/theme/src/hooks/useDataLayerPush';

export interface DataLayerWrapperProps {
  children: any;
  data?: PageProps['data'];
  serverData?: PageProps['serverData'];
  location: PageProps['location'];
}

export const DataLayerWrapper: FC<
  React.PropsWithChildren<DataLayerWrapperProps>
> = ({ children, data, serverData, location }) => {
  const { dataLayerPush } = useDataLayerPush();

  // Handle route change
  useEffect(() => {
    const pageData = {
      event: 'virtual_pageview',
      spa_page_url: location?.href,
    };

    dataLayerPush({
      ...pageData,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return children;
};
