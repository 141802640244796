import { shade, tint } from 'polished';

type ColorTones<Key extends string> = Record<
  `${Key}120` | `${Key}100` | `${Key}80` | `${Key}50` | `${Key}20` | `${Key}10`,
  string
>;

export const getColorTones = <Key extends string>(
  color: string,
  key: Key
): ColorTones<Key> => {
  const tones = {
    [`${key}120`]: shade(0.2, color),
    [`${key}100`]: color,
    [`${key}80`]: tint(0.2, color),
    [`${key}50`]: tint(0.5, color),
    [`${key}20`]: tint(0.8, color),
    [`${key}10`]: tint(0.9, color),
  } as ColorTones<Key>; // Use a type assertion here to specify the type

  return tones;
};
