import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Minus: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 15v2h16v-2H8Z"
      clipRule="evenodd"
    />
  </Icon>
);
