import { graphql, useStaticQuery } from 'gatsby';

interface useSiteCountryReturn {
  country: 'UK' | 'IE';
  currencyCode: 'EUR' | 'GBP';
  isUk: boolean;
  isIe: boolean;
}

export const useSiteCountry = (): useSiteCountryReturn => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query getSiteCountry {
      site {
        siteMetadata {
          country
          currency
        }
      }
    }
  `);

  return {
    country: siteMetadata.country,
    currencyCode: siteMetadata.currency,
    isUk: siteMetadata.country === 'UK',
    isIe: siteMetadata.country === 'IE',
  };
};
