import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';
import { isProd } from '@agria/utils';

export const ScriptsBefore = () => (
  <>
    {/* GTM */}
    <Script strategy={ScriptStrategy.postHydrate}>
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KFNX8D');
      `}
    </Script>
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-KFNX8D"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
        title="gtm-frame"
      />
    </noscript>
    <Script
      strategy={ScriptStrategy.postHydrate}
      type="text/javascript"
      src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
      charSet="UTF-8"
      data-domain-script={`957e6e5e-b4c1-4ba8-a9eb-242288bae1c5${
        !isProd ? '-test' : ''
      }`}
      // data-domain-script={`fc0000f0-abef-4c51-9570-2bed424a64b2${
      //   !isProd ? '-test' : ''
      // }`}
    />
    <Script
      strategy={ScriptStrategy.postHydrate}
      type="text/javascript"
      dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
    />
    {/* TVSquared Tracking Code */}
    <Script strategy={ScriptStrategy.postHydrate}>
      {`
        var _tvqsky = window._tvqsky = window._tvqsky || [];
        (function() {
        var u = (("https:" == document.location.protocol) ?
        "https://collector-31509.tvsquared.com/"
        :
        "http://collector-31509.tvsquared.com/");
        _tvqsky.push(['setSiteId', "TV-6381459009-1"]);
        _tvqsky.push(['setTrackerUrl', u + 'tv2track.php']);
        _tvqsky.push([function() {
        this.deleteCustomVariable(5, 'page')
        }]);
        _tvqsky.push(['trackPageView']);
        var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
        g.type = 'text/javascript';
        g.defer = true;
        g.async = true;
        g.src = u + 'tv2tracksky.js';
        s.parentNode.insertBefore(g, s);
        })();
      `}
    </Script>

    {/* Dax radio tracking */}
    <img
      src="https://tracking.audio.thisisdax.com/one.png?client=AgriaPets&action=cs&eventId=&event=JourneyAppe&j=0"
      width="0"
      height="0"
      style={{ display: 'none' }}
      alt=""
    />
    {/* Optimise */}
    <Script strategy={ScriptStrategy.postHydrate}>
      {`
       OMID=1353001;OPID=35329;ORef=escape(window.parent.location.href);
       !function(){var a=document.createElement("script");a.type="text/javascript",a.async=!0,
       a.src="//track.omguk.com/e/qs/?action=Content&MID=" + OMID + "&PID=" + OPID + "&ref="+ ORef;
       var b=document.getElementsByTagName("body")[0];if(b)b.appendChild(a,b);
       else{var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b)}}();
      `}
    </Script>
    {/* Pixel */}
    <Script strategy={ScriptStrategy.postHydrate}>
      {`
       !function(e,i){if(!e.pixie){var n=e.pixie=function(e,i,a){n.actionQueue.push({action:e,actionValue:i,params:a})};n.actionQueue=[];var a=i.createElement("script");a.async=!0,a.src="//acdn.adnxs.com/dmp/up/pixie.js";var t=i.getElementsByTagName("head")[0];t.insertBefore(a,t.firstChild)}}(window,document);
       pixie('init', '4d07860b-0dfc-41a7-8244-3cebd93c414f');
      `}
    </Script>
    {/* Bing Ads */}
    <Script strategy={ScriptStrategy.postHydrate}>
      {`
       (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[] ,f=function(){var o={ti:"17219122"}; o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")} ,n=d.createElement(t),n.src=r,n.async=1,n.onload=n .onreadystatechange=function() {var s=this.readyState;s &&s!=="loaded"&& s!=="complete"||(f(),n.onload=n. onreadystatechange=null)},i= d.getElementsByTagName(t)[0],i. parentNode.insertBefore(n,i)})(window,document,"script"," //bat.bing.com/bat.js","uetq");
      `}
    </Script>
    {/* Yieldify */}
    <Script strategy={ScriptStrategy.postHydrate}>
      {`
       (function(d){var e=d.createElement('script');e.src='https://td.yieldify.com/yieldify/code.js?w_uuid=86e7803e-dac4-4d96-82c8-73a60ad58552&k=1&loca='+window.location.href;e.async=true;d.getElementsByTagName('head')[0].appendChild(e);}(document));
      `}
    </Script>
    {/* Smarter tracking */}
    <Script strategy={ScriptStrategy.postHydrate}>
      {`
          (function (s, m, a, r, t) { s[r] = s[r] || []; var f = m.getElementsByTagName(a)[0], j = m.createElement(a), dl = r != 'dataLayer' ? '&r=' + r : ''; j.async = true; j.src = '//smct.co/tm/?t=' + t + dl; f.parentNode.insertBefore(j, f); }
          )(window, document, 'script', 'dataLayer', 'agriapet.co.uk');
       `}
    </Script>
  </>
);
