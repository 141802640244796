import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const VideoPlayIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29Z"
      fill="#02315B"
    />
    <path
      d="M12.1323 22.8921L22.9423 16.6491C23.4481 16.3623 23.4481 15.6301 22.9423 15.3433L12.1323 9.1003C11.6266 8.81345 11 9.17579 11 9.75705V22.2429C11 22.8242 11.6266 23.1866 12.1323 22.8997V22.8921Z"
      fill="white"
    />
  </Icon>
);