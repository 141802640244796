import { useMutation } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: MJS workaround to allow in JS file
import { service } from '../client.mjs';

import { ApiResponse } from '../types';
import { dataOrError } from '../utils/dataOrError';

export interface CheckBreederEmailPayload {
  email?: string;
}

const getCheckBreederEmail = async ({ email }: CheckBreederEmailPayload) => {
  const { data } = await service.post<ApiResponse<string>>(
    `/Affiliate/CheckBreederEmail?emailAddress=${email ?? ''}`
  );
  return dataOrError<string>(data);
};

export function useCheckBreederEmail() {
  return useMutation({
    mutationFn: getCheckBreederEmail
  });
}
