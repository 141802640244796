import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ArrowRight: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m22.843 22.715-1.414-1.414 4.307-4.307H2.44v-2h23.29l-4.3-4.3 1.413-1.414 6.718 6.718-6.718 6.717Z"
      clipRule="evenodd"
    />
  </Icon>
);
