import React, {
  MutableRefObject,
  RefObject,
  createContext,
  useMemo,
  useState,
} from 'react';

export type Blades = string | 'storybookDemo' | 'map' | 'digitalBanner' | null;

type BladeWidths = 'narrow' | 'wide';

type ActiveBladeTriggerElType =
  | MutableRefObject<HTMLButtonElement>
  | RefObject<HTMLButtonElement>
  | undefined
  | null;

interface UiStateContextProps {
  activeBlade: Blades;
  setActiveBlade: React.Dispatch<React.SetStateAction<Blades>>;
  activeSecondaryBlade: Blades;
  setActiveSecondaryBlade: React.Dispatch<React.SetStateAction<Blades>>;
  activeBladeId: string | undefined;
  setActiveBladeId: React.Dispatch<React.SetStateAction<string | undefined>>;
  activeBladeTriggerEl: ActiveBladeTriggerElType;
  setActiveBladeTriggerEl: React.Dispatch<
    React.SetStateAction<ActiveBladeTriggerElType | undefined>
  >;
  activeSecondaryBladeTriggerEl: ActiveBladeTriggerElType;
  setActiveSecondaryBladeTriggerEl: React.Dispatch<
    React.SetStateAction<ActiveBladeTriggerElType | undefined>
  >;
  activeBladeWidth: BladeWidths | undefined;
  setActiveBladeWidth: React.Dispatch<
    React.SetStateAction<BladeWidths | undefined>
  >;
  activeBladeAnimate: boolean | undefined;
  setActiveBladeAnimate: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
}

export const UiStateContext = createContext({} as UiStateContextProps);
UiStateContext.displayName = 'UiStateContext';

export const UiStateContextProvider = ({ children }) => {
  const [activeBlade, setActiveBlade] = useState<Blades>(null);
  const [activeSecondaryBlade, setActiveSecondaryBlade] =
    useState<Blades>(null);
  const [activeBladeId, setActiveBladeId] = useState<string | undefined>(
    undefined
  );
  const [activeBladeTriggerEl, setActiveBladeTriggerEl] =
    useState<ActiveBladeTriggerElType>(undefined);
  const [activeSecondaryBladeTriggerEl, setActiveSecondaryBladeTriggerEl] =
    useState<ActiveBladeTriggerElType>(undefined);

  const [activeBladeWidth, setActiveBladeWidth] = useState<
    BladeWidths | undefined
  >(undefined);
  const [activeBladeAnimate, setActiveBladeAnimate] = useState<
    boolean | undefined
  >(true);

  const contextValues: UiStateContextProps = useMemo(
    () => ({
      activeBlade,
      setActiveBlade,
      activeSecondaryBlade,
      setActiveSecondaryBlade,
      activeBladeId,
      setActiveBladeId,
      activeBladeTriggerEl,
      setActiveBladeTriggerEl,
      activeSecondaryBladeTriggerEl,
      setActiveSecondaryBladeTriggerEl,
      activeBladeWidth,
      setActiveBladeWidth,
      activeBladeAnimate,
      setActiveBladeAnimate,
    }),
    [
      activeBlade,
      setActiveBlade,
      activeSecondaryBlade,
      setActiveSecondaryBlade,
      activeBladeId,
      setActiveBladeId,
      activeBladeTriggerEl,
      setActiveBladeTriggerEl,
      activeSecondaryBladeTriggerEl,
      setActiveSecondaryBladeTriggerEl,
      activeBladeWidth,
      setActiveBladeWidth,
      activeBladeAnimate,
      setActiveBladeAnimate,
    ]
  );

  return (
    <UiStateContext.Provider value={contextValues}>
      {children}
    </UiStateContext.Provider>
  );
};
