import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const TickCircle: FC<IconProps> = (props) => (
  <Icon {...props} title={props.title || "Tick icon"}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 27C9.935 27 5 22.065 5 16S9.935 5 16 5s11 4.935 11 11-4.935 11-11 11Zm0-24C8.832 3 3 8.832 3 16s5.832 13 13 13 13-5.832 13-13S23.168 3 16 3Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m14.545 18.593-3.287-3.342-1.424 1.404 4.697 4.772 8.037-7.99-1.41-1.418-6.613 6.574Z"
      clipRule="evenodd"
    />
  </Icon>
);
