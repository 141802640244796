import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { GatsbySSR } from 'gatsby';
import React from 'react';
// Create a client
const queryClient = new QueryClient();

export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    {element} <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
