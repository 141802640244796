import { useEffect, useState } from 'react';
import { speciesReference } from '../const/speciesReference';
import { SpeciesReferenceItem } from '../types/global';

export const useSpeciesInfo = (
  speciesId?: string
): SpeciesReferenceItem | null => {
  const [speciesInfo, setSpeciesInfo] = useState<SpeciesReferenceItem | null>(
    null
  );

  useEffect(() => {
    if (speciesId && speciesReference[speciesId]) {
      setSpeciesInfo(speciesReference[speciesId]);
    }
  }, [speciesId]);

  return speciesInfo;
};

export default useSpeciesInfo;
