import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Close: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m22.286 8.3-6.28 6.281L9.725 8.3 8.311 9.714l6.281 6.282L8.3 22.289l1.414 1.414 6.292-6.293 6.28 6.282 1.414-1.414-6.281-6.282L23.7 9.714 22.286 8.3Z"
      clipRule="evenodd"
    />
  </Icon>
);
