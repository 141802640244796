import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Plus: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 8v7H8v2h7v7h2v-7h7v-2h-7V8h-2Z"
      clipRule="evenodd"
    />
  </Icon>
);
