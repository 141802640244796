import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Warning: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 18.25h2v-9h-2v9Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 27C9.935 27 5 22.065 5 16S9.935 5 16 5s11 4.935 11 11-4.935 11-11 11Zm0-24C8.832 3 3 8.832 3 16s5.832 13 13 13 13-5.832 13-13S23.168 3 16 3Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.017 20a1.5 1.5 0 1 0 0 3.001 1.5 1.5 0 0 0 0-3.001Z"
      clipRule="evenodd"
    />
  </Icon>
);
