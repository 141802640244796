import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Star: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.588 17.704a.997.997 0 0 0-.359 1.111l1.664 5.177-4.296-3.192a1 1 0 0 0-1.194 0l-4.294 3.192 1.664-5.177a1.001 1.001 0 0 0-.36-1.111l-4.359-3.209 5.361.012h.002a.999.999 0 0 0 .953-.699L16 8.662l1.631 5.146a1 1 0 0 0 .953.699h.002l5.36-.012-4.358 3.209Zm8.362-4.529a1.001 1.001 0 0 0-.95-.687h-.002l-7.682.017-2.363-7.456a1 1 0 0 0-1.906 0l-2.36 7.456-7.685-.017H5a1.002 1.002 0 0 0-.593 1.806l6.242 4.595-2.399 7.46a1 1 0 0 0 1.55 1.108l6.2-4.608 6.202 4.608a1.001 1.001 0 0 0 1.55-1.108l-2.399-7.46 6.24-4.595a1 1 0 0 0 .357-1.119Z"
      clipRule="evenodd"
    />
  </Icon>
);
