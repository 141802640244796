import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ArrowDown: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.28 22.843 1.414-1.415 4.307 4.307V2.439h2v23.29l4.3-4.3 1.414 1.414-6.717 6.717-6.718-6.717Z"
      clipRule="evenodd"
    />
  </Icon>
);
