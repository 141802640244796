import { isProd } from '@agria/utils';

const siteLinksDev = {
  brochureware: 'https://www.kcinsurance.co.uk',
  makeAPayment: 'https://kc-make-a-payment.netlify.app',
  onlineVetCLaims: 'https://vetclaim.kcinsurance.co.uk',
  onlineCustomerClaims: 'https://claim.kcinsurance.co.uk',
  quoteAndBuy: 'https://kc-qab.netlify.app',
  quoteAndBuyContinueCover: 'https://kc-qab.netlify.app/continue-cover',
  quoteAndBuyRetrieve: 'https://kc-qab.netlify.app/retrieve',
  freeCover: 'https://kc-owner-activated.netlify.app',
  rehomingCover: null,
  vetActivatedCover: null,
  breederClub: 'https://kc-breeder-club.netlify.app',
  vetOwnerActivated: null,
  litterDataCapture: 'https://litter-data-capture.kcinsurance.co.uk/',
  joinBreederClub: 'https://kc-join-breeder-club.netlify.app/signup-type',
  transferCover: 'https://kc-transfer-cover.netlify.app',
  myAccount: 'https://www.kcinsurance.co.uk/my-account',
  stock: 'https://stock.kcinsurance.co.uk/login.html',
  vetEnvoy: 'https://www.vetenvoy.com/',
  myRewards: 'https://kcinsurancerewards.co.uk/',
};

const siteLinksProduction = {
  ...siteLinksDev,
  quoteAndBuy: 'https://quote.kcinsurance.co.uk',
  quoteAndBuyContinueCover: 'https://quote.kcinsurance.co.uk/continue-cover',
  quoteAndBuyRetrieve: 'https://quote.kcinsurance.co.uk/retrieve',
  brochureware: 'https://www.kcinsurance.co.uk',
  freeCover: 'https://owners.kcinsurance.co.uk',
  breederClub: 'https://breeders.kcinsurance.co.uk',
  joinBreederClub: 'https://join.kcinsurance.co.uk/signup-type',
  transferCover: 'https://transfer-cover.kcinsurance.co.uk',
};
export const siteLinks = isProd ? siteLinksProduction : siteLinksDev;
