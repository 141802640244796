import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Error: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.67 19.83h2v-7h-2v7ZM15.685 21.58c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M30.945 28.96H1.04L15.65 3.03l15.29 25.93h.005ZM4.55 26.91h22.805L15.69 7.135 4.55 26.91Z"
    />
  </Icon>
);
