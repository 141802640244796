const gradients = {
  netural100Vertical:
    'linear-gradient(360deg, #033137 24.18%, rgba(3, 49, 55, 0) 100%)',
  netural100Horizontal:
    'linear-gradient(90deg, #033137 0%, rgba(3, 49, 55, 0) 100%)',
} as const;

export default gradients;

export type ThemeGradients = typeof gradients;
export type ThemeGradientsKeys = keyof typeof gradients;
