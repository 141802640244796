// get the value of a array from an id

export const getValueFromId = <T>(id: string, array: T[], prop = 'name') => {
  if (!id || !array || array.length <= 0) {
    return null;
  }
  return (array as any[]).find((item) => item.Id === id || item.id === id)?.[
    prop
  ];
};
