import { FC, useContext, useEffect } from 'react';
import { isBrowser } from '@agria/utils';
import { ExperimentsContext } from '../context/ExperimentsContext';

export interface ExperimentsWrapperProps {
  children: any;
  data: any; // TODO - does gatsby have a type for this??
}

export const ExperimentsWrapper: FC<ExperimentsWrapperProps> = ({
  children,
  data,
}) => {
  const { setExperiments } = useContext(ExperimentsContext);

  // Handle route change

  useEffect(() => {
    const waitForGlobal = function (key, callback) {
      if (window[key]) {
        callback();
      } else {
        setTimeout(() => {
          waitForGlobal(key, callback);
        }, 100);
      }
    };

    if (isBrowser) {
      waitForGlobal('experiments', () => {
        setExperiments(window.experiments);
      });
    }
  }, [data, setExperiments]);

  return children;
};
