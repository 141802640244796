import { colors } from '../../styles/tokens';

export default {
  disabledOpacity: '0.2',
  disabledTextColor: 'utilityWhite',
  disabledBackgroundColor: 'brandTwo100',
  borderRadius: 'small',
  focusVisible: `${colors.systemFocus};`,
  focusValid: `${colors.systemFocusValid};`,
  focusInvalid: `${colors.systemFocusInvalid};`,
};
