import { colors } from '@agria/theme/src/styles/tokens/colors';

type ColorMapType = {
  [animalName: string]: keyof typeof colors;
};

export const getHighlightColor = (
  animalName: string,
  tone?: '100' | '80' | '50' | '20',
  defaultTone?: '100' | '50' | '20'
) => {
  const colorMap: ColorMapType = {
    none: `brandOne${tone || '100'}`,
    dog: `brandOne${tone || '80'}`,
    puppy: `brandOne${tone || '80'}`,
  };

  // Convert the animal name to lowercase to make the function case-insensitive.
  const animalKey = animalName?.toLowerCase();

  // If the animal is in the map, return its color. Otherwise, return a default color.
  return colorMap[animalKey] || `brandOne${defaultTone || '80'}`;
};
