import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Filter: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M12.525 7.225a3.5 3.5 0 0 0-3.295-2.33 3.5 3.5 0 0 0-3.295 2.33H3.03v2.47h2.95a3.502 3.502 0 0 0 6.5 0h16.495v-2.47h-16.45ZM9.23 9.4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Zm2.965 10.545c-1.53 0-2.83.985-3.305 2.35H3.03v2.47h5.925a3.503 3.503 0 0 0 3.24 2.18c1.465 0 2.72-.9 3.24-2.18H28.97v-2.47H15.495a3.496 3.496 0 0 0-3.305-2.35h.005Zm0 4.5c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1ZM22.745 12.5c-1.5 0-2.77.94-3.27 2.265H3.03v2.47h16.44a3.496 3.496 0 0 0 3.27 2.265c1.495 0 2.77-.94 3.27-2.265h2.955v-2.47H26.01a3.496 3.496 0 0 0-3.27-2.265h.005Zm0 4.5c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Z"
    />
  </Icon>
);
