export const subSpeciesIds = [
  {
    name: 'service dog',
    id: 'b9b3fb6d-ec76-48bd-934a-22db7a0f5299',
  },
  {
    name: 'home cat',
    id: '0497e2aa-014a-4a57-8406-7168fa3f7679',
  },
];
