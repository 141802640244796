import React, { FC } from 'react';
import { SimpleFooter } from '@agria/theme/src/components/footers/simpleFooter/SimpleFooter';
import { useSiteMetadata } from '@agria/theme/src/hooks/useSiteMetadata';

// Interface

export interface FooterProps {}

// JSX

export const Footer: FC<FooterProps> = () => {
  const { footerTerms } = useSiteMetadata();
  return <SimpleFooter footerTerms={footerTerms ?? ''} />;
};

export default Footer;
